<template>
  <layout-view-wrapper-narrow class="content">
    <BaseH1 :text="'Rejestracja'" :short="true" />
    <TextDescription :text="'Wybierz, co ci pasuje'" />
    <div class="wrapper">
      <ButtonBasic
        @click="handleUserTypeChoice('client')"
        :label="'Szukam niani'"
      />
      <ButtonBasic
        @click="handleUserTypeChoice('contractor')"
        :label="'Jestem nianią'"
      />
      <!-- <ButtonLink
        :to="{ name: 'RegistrationClientStartBusiness' }"
        :label="'Jestem agencją'"
      /> -->
    </div>
  </layout-view-wrapper-narrow>
</template>

<script>
import { useRouter } from "vue-router";

import { ROUTE_REGISTRATION_DETAILS_NAME } from "@/router/constants";

import ButtonBasic from "@/components/UI/Base/ButtonBasic.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";

export default {
  components: {
    TextDescription,
    ButtonBasic,
    BaseH1,
  },

  setup() {
    const router = useRouter();
    const handleUserTypeChoice = (userType) => {
      router.push({
        name: ROUTE_REGISTRATION_DETAILS_NAME,
        query: {
          // TODO: maybe better to save it in LS for persistence around the whole site
          userType,
        },
      });
    };

    return {
      handleUserTypeChoice,
    };
  },
};
</script>

<style scoped>
.content {
  justify-content: flex-start;
  row-gap: 80px;
}
.wrapper {
  width: 360px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

@media (max-width: 1200px) {
  .content {
    row-gap: 60px;
  }
  .wrapper {
    width: 100%;
    gap: 40px;
  }
}
</style>
